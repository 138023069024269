import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import {
  useSuspendedQuery,
  useZepApiClient,
  UseZepApiQueryOptions,
  useZepetofulContents,
} from '@zep/apis';

import { ManageQueryKeys, ZepetofulEntries } from '../consts';
import { ApiCommonResponse, StoreItem, StoreItemSale } from '../types';
import { Donation, DonationsResponse } from '../types/DonationTypes';
import {
  ApiResponsePagerType,
  LandingSectionType,
  OfficeSectionType,
  ReferenceData,
} from '../types/GlobalTypes';

import { ZepApp } from './accountsService';

/**
 * 현재 내가 제작한 앱의 리스트를 가져오는 API(에셋 스토어 앱, 게임 업로드시 사용)
 * @returns 제작한 앱 목록
 */
export const useMyApps = (
  options?: UseZepApiQueryOptions<{
    status: number;
    apps: ZepApp[];
  }>,
) => {
  const client = useZepApiClient();
  return useQuery(
    [ManageQueryKeys.MY_APP_LIST_GET],
    async () => {
      const { data } = await client.get('/v2/me/apps');

      return data;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    options,
  );
};

/**
 * 후원 내역 가져오는 API
 * @param viewType 받은 내역 / 보낸 내역 구분
 * @param donationType 후원 방식 - ZEM / Token
 * @param page 페이지 번호
 * @param limit 가져올 개수
 * @param options
 * @returns 후원 내역
 */
export const useDonations = (
  viewType: 'received' | 'sent',
  donationType: string,
  page = 1,
  limit = 10,
  options?: UseZepApiQueryOptions<{
    donations: Donation[];
    pager: ApiResponsePagerType;
    remainingAmount: number;
  }>,
) => {
  const client = useZepApiClient();

  return useQuery(
    [ManageQueryKeys.MY_DONATIONS, viewType, donationType, page, limit],
    async () => {
      const { data } = await client.get<DonationsResponse>(
        `/v2/me/donations/${viewType}`,
        {
          params: {
            type: donationType,
            page,
            limit,
          },
        },
      );
      const { results, pager, remainingAmount } = data;

      return {
        donations: results,
        pager,
        remainingAmount: remainingAmount ?? 0,
      };
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { ...options, keepPreviousData: false },
  );
};

export function useExplore(
  isEdu: boolean,
  options?: UseZepApiQueryOptions<ReferenceData>,
) {
  return useZepetofulContents(
    isEdu ? ZepetofulEntries.EXPLORE_EDU : ZepetofulEntries.EXPLORE,
    options,
  );
}

export function useLanding(
  options?: UseZepApiQueryOptions<LandingSectionType>,
) {
  return useZepetofulContents(ZepetofulEntries.LANDING_PAGE, options);
}

export function useOfficeLanding(
  options?: UseZepApiQueryOptions<OfficeSectionType>,
) {
  return useZepetofulContents(ZepetofulEntries.OFFICE_PAGE, options);
}

export function useOnlineClassLanding(
  options?: UseZepApiQueryOptions<LandingSectionType>,
) {
  return useZepetofulContents(ZepetofulEntries.ONLINE_CLASS_PAGE, options);
}

export const useMyAssets = (page = 1, limit = 5) => {
  const client = useZepApiClient();
  const { locale } = useRouter();

  return useSuspendedQuery(
    [ManageQueryKeys.MY_ASSETS, page, limit],
    async () => {
      const { data } = await client.get<
        ApiCommonResponse & {
          data: {
            items: StoreItem[];
            itemCount: number;
            pager: ApiResponsePagerType;
          };
        }
      >('/v2/store/me/items', {
        params: {
          page,
          limit,
          locale,
        },
      });

      return data.data;
    },
  );
};

export const useSales = (page = 1, limit = 20, enabled = false) => {
  const client = useZepApiClient();
  const { locale } = useRouter();

  return useSuspendedQuery(
    [ManageQueryKeys.MY_SALES, page, limit],
    async () => {
      const { data } = await client.get<
        ApiCommonResponse & {
          data: {
            purchases: StoreItemSale[];
            purchaseCount: number;
            totalRevenue: number;
            pager: ApiResponsePagerType;
          };
        }
      >('/v2/store/me/sales', {
        params: {
          page,
          limit,
          locale,
        },
      });

      const { purchaseCount, pager, totalRevenue = 0, purchases } = data.data;

      return {
        sales: purchases,
        totalRevenue,
        purchaseCount,
        pager,
      };
    },
  );
};
